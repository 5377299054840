import React, { Component } from 'react';

import { compose } from 'redux';
import { firebaseConnect } from 'react-redux-firebase';

class Login extends Component {
	state = {
		email: null,
		password: null,
		error: null,
		loggingIn: false
	}

	componentDidMount() {
		this.login = this.login.bind(this);
	}

	login() {
		this.setState({ error: null, loggingIn: true });

		try {
			this.props.firebase.login({
				email: this.state.email,
				password: this.state.password
			})
			.then((confirmationResult) => {
				if (confirmationResult.user.operationType === "signIn") {
					this.props.history.push('/');
				}
			})
			.catch((error) => {
				return Promise.reject(error);
			});
		} catch (error) {
			console.log('hasError', error);
			this.setState({ error, loggingIn: false });
		}
	}

	render() {
		const { error } = this.state;

		return <div className="login-form">
			<div className="form-header">
				<h1>Login</h1>
			</div>

			<form
				onSubmit={(e) => {
					e.preventDefault();
					this.login();
				}}>

				<div className="input">
					<label htmlFor="email">Email</label>
					<input type="text" id="email" onChange={e => this.setState({ email: e.target.value })} />
				</div>

				<div className="input">
					<label htmlFor="password">Password</label>
					<input type="password" id="password" onChange={e => this.setState({ password: e.target.value })} />
				</div>

				<input type="submit" style={{ display: 'none' }} />
				<div className="button" onClick={this.login.bind(this)}>Login</div>

				{error && error.message && <div className="form-error">{error.message}</div>}
			</form>
 		</div>
	}
}

export default compose(
	firebaseConnect()
)(Login);

// export default Login;
