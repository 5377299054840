/*
 * Articles
 */
export const GET_ARTICLES 			= 'GET_ARTICLES';
export const GET_ARTICLES_SUCCESS 	= 'GET_ARTICLES_SUCCESS';
export const GET_ARTICLES_FAIL 		= 'GET_ARTICLES_FAIL';

/*
 * Article
 */
export const GET_ARTICLE 			= 'GET_ARTICLE';
export const GET_ARTICLE_SUCCESS 	= 'GET_ARTICLE_SUCCESS';
export const GET_ARTICLE_FAIL 		= 'GET_ARTICLE_FAIL';
