import axios from 'axios';

import { API_ENDPOINT } from 'config/constants';
import {
	GET_ARTICLES,
	GET_ARTICLES_SUCCESS,
	GET_ARTICLES_FAIL,
} from 'config/actions';

export const getArticles = () => {
	return (dispatch, getState) => {
		dispatch({ type: GET_ARTICLES });

		return axios.get(`${API_ENDPOINT}/articles`)
		.then(res => {
			dispatch({
				type: GET_ARTICLES_SUCCESS,
				payload: res.data
			});

			return true;
		})
		.catch(err => {
			dispatch({
				type: GET_ARTICLES_FAIL,
				payload: err
			});

			throw err;
		});
	}
}

export default (state={}, action) => {
	switch (action.type) {
		case GET_ARTICLES:
			return {
				...state,
				actionType: GET_ARTICLES,
				hasError: false,
				isFetching: true,
				hasSuccess: false,
			}

		case GET_ARTICLES_SUCCESS:
			return {
				...state,
				actionType: GET_ARTICLES_SUCCESS,
				data: action.payload,
				hasError: false,
				hasSuccess: true,
				isFetching: false,
			}

		case GET_ARTICLES_FAIL:
			return {
				...state,
				actionType: GET_ARTICLES_FAIL,
				data: null,
				isFetching: false,
				hasError: true,
				hasSuccess: false,
				error: action.payload
			}

		default:
			return state;
	}
}
