import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';

class Sidebar extends Component {
	state = {}

	componentDidMount() {
		const { articles } = this.props;
		const groupStates = articles && articles.data && articles.data.reduce((obj, article) => article.children
			? {...obj, [`show-${article.name}`]: this.props.location.pathname.includes(article.name)}
			: obj, {});
		this.setState(groupStates);
	}

	renderItem({ article, path, i }) {
		return <NavLink to={`${path}/${article.name}`} className="nav-article-group-item" key={i}>{article.title || article.name} {article.isDraft && '(draft)'}</NavLink>
	}

	renderGroup({ article, path, i }) {
		return <div key={i} className={["nav-article-group", this.state[`show-${article.name}`] ? 'show' : 'hide'].join(' ')}>
			<div className="nav-article-group-header" onClick={() => this.setState({ [`show-${article.name}`]: !this.state[`show-${article.name}`] })}>{article.title || article.name} {article.isDraft && '(draft)'}</div>
			<div className="nav-article-group-children">{this.renderArticles({ articles: article.children, path: path += '/' + article.name })}</div>
		</div>
	}

	renderArticles({ articles, path='' }) {
		return articles
			.filter(article => article.isDraft ? ((this.props.profile||{}).modi||{}).dogfood : true)
			.map((article, i) => article.children
				? this.renderGroup({ article, path, i })
				: this.renderItem({ article, path, i })
			);
	}

	render() {
		const { articles } = this.props;
		return <div className="sidebar">
			{articles && articles.data && this.renderArticles({ articles: articles.data })}
		</div>
	}
}

function mapStateToProps(state) {
	return {
		articles: state.articles,
		profile: state.firebase.profile
	}
}

export default connect(mapStateToProps)(Sidebar);
