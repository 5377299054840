import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';

import articles from './articles';
import article from './article';

const createRootReducer = (history) => combineReducers({
	router: connectRouter(history),
	firebase: firebaseReducer,
	firestore: firestoreReducer,
	articles,
	article,
});

export default createRootReducer;