import React, { Component } from 'react';
import ReactMarkdown from 'react-markdown';
import { connect } from 'react-redux';

import { getArticle } from 'modules/article';
import Sidebar from './sidebar';
import { API_ENDPOINT } from 'config/constants';

class Page extends Component {
	componentDidMount() {
		this.props.getArticle(this.props.match.path);
	}

	renderParagraph(props) {
		const { children } = props;

		return children && children[0]
			&& children.length === 1
			&& children[0].props
			&& children[0].props.src
		? children
		: <p>{children}</p>;
	}

	renderImage(props) {
		const { alt, src } = props;
		return <div className="article-image">{src && <img alt={alt} src={src} />}</div>;
	}

	renderLink(props) {
		const { href, children } = props;
		return <a href={href} target='_blank'>{children}</a>
	}

	render() {
		const { article } = this.props;

		return <div className="page">
			<Sidebar {...this.props} />

			{article && article.data && <div className="article">
				<ReactMarkdown
					transformImageUri={(uri) => `${API_ENDPOINT}/${this.props.match.path}/${uri}` }
					className="article-content"
					renderers={{
						paragraph: this.renderParagraph,
						image: this.renderImage,
						link: this.renderLink, 
					}}
					source={article.data} />
			</div>}
		</div>
	}
}

function mapStateToProps(state) {
	return {
		article: state.article
	}
}

export default connect(mapStateToProps, { getArticle })(Page);
