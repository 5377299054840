import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import store, { history, rrfProps } from 'store/index';

import 'styles/index.css';
import App from 'components/app';
import * as serviceWorker from './serviceWorker';

/*
 * Provide firebase config details before starting at config/firebase.json
 */

ReactDOM.render(
	<Provider store={store}>
		<ReactReduxFirebaseProvider {...rrfProps}>
			<ConnectedRouter history={history}>
				<App />
			</ConnectedRouter>
		</ReactReduxFirebaseProvider>
	</Provider>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
