import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { firebaseConnect } from 'react-redux-firebase';
import { userIsAuthenticatedRedir, userIsNotAuthenticatedRedir } from 'modules/auth';

import {
	getArticles
} from 'modules/articles';

import {
	Page,
	Header,
	// Footer,
	Login,
} from 'components/index';

import { Dots } from 'react-activity';
import 'react-activity/dist/react-activity.css';

class App extends Component {
	componentDidMount() {
		this.props.getArticles();
	}

	renderArticleGroup({ articles, path='' }) {
		return articles.map((article, i) => article.children
			? this.renderArticleGroup({ articles: article.children, path: `${path}/${article.name}` })
			: this.renderArticle({ article, path, i })
		)
	}

	renderArticle({ article, path, i }) {
		const hoc = userIsAuthenticatedRedir(Page);
		return <Route key={i} path={`${path}/${article.name}`} component={hoc} />;
	}

	logout() {
		this.props.firebase.logout();
	}

	render() {
		const { auth, profile, articles } = this.props;

		return (
			<main>
				<Header profile={profile} logout={this.logout.bind(this)} />
				<div className="main">
				{auth.isLoaded
					? <Switch>
						<Route exact path="/" render={() => <Redirect to={'/getting-started/embed-clickflow'} />} />

						<Route path="/login" component={userIsNotAuthenticatedRedir(Login)} />

						{articles.data && this.renderArticleGroup({ articles: articles.data })}

						<Route path={`/test`} component={Page} />
					</Switch>
					: <div style={{ marginTop: 100, textAlign: 'center' }}><Dots size={24} /></div>}
				</div>
				{/* <Footer /> */}
			</main>
		)
	}
}

function mapStateToProps(state) {
	return {
		auth: state.firebase.auth,
		profile: state.firebase.profile,
		articles: state.articles,
	}
}

export default compose(
	withRouter,
	firebaseConnect(),
	connect(({ firebase: { auth, profile } }) => ({ auth, profile })),
	connect(mapStateToProps, { getArticles })
)(App);
