import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper';
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
// import connectedAuthWrapper from 'redux-auth-wrapper/connectedAuthWrapper';

const locationHelper = locationHelperBuilder({});

const userIsAuthenticatedDefaults = {
	authenticatedSelector: state => !state.firebase.auth.isEmpty,
	authenticatingSelector: state => state.firebase.auth.isLoading,
	wrapperDisplayName: 'UserIsAuthenticated'
}

export const userIsAuthenticatedRedir = connectedRouterRedirect({
	...userIsAuthenticatedDefaults,
	redirectPath: '/login'
})

const userIsNotAuthenticatedDefaults = {
	// Want to redirect the user when they are done loading and authenticated
	authenticatedSelector: state => state.firebase.auth.isEmpty && !state.firebase.auth.isLoading,
	wrapperDisplayName: 'UserIsNotAuthenticated'
}

export const userIsNotAuthenticatedRedir = connectedRouterRedirect({
	...userIsNotAuthenticatedDefaults,
	redirectPath: (state, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || '/',
	allowRedirectBack: false
});
