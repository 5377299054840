import {
	createStore,
	applyMiddleware,
	compose
} from 'redux';

import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import promise from 'redux-promise';

import { createFirestoreInstance, reduxFirestore } from 'redux-firestore';
import firebase from 'firebase/app';

import 'firebase/auth'
import 'firebase/firestore'

import { createBrowserHistory } from 'history';
import createRootReducer from 'modules/index';

import firebaseConfig from 'config/firebase';

firebase.initializeApp(firebaseConfig);
const firestore = firebase.firestore();
firestore.settings({});

const rfConfig = {
	userProfile: 'users',
	useFirestoreForProfile: true,
	attachAuthIsReady: true,
}

export const history = createBrowserHistory();

const enhancers = [];
const middleware = applyMiddleware(thunk, promise, logger, routerMiddleware(history));

if (process.env.NODE_ENV === 'development') {
	const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

	if (typeof devToolsExtension === 'function') {
		enhancers.push(devToolsExtension());
	}
}

const createStoreWithFirebase = compose(
	reduxFirestore(firebase, rfConfig)
)(createStore);

const store = createStoreWithFirebase(
	createRootReducer(history),
	compose(
		middleware,
		...enhancers
	)
);

export const rrfProps = {
	firebase,
	config: rfConfig,
	dispatch: store.dispatch,
	createFirestoreInstance,
}

export default store;
