import React, { Component } from 'react';
import Logo from 'assets/zenflow-logo-highlight.svg';

class Header extends Component {
	render() {
		const { profile } = this.props;

		return <header>
			<div>
				<img className="zenflow-logo" src={'https://storage.googleapis.com/niro-eu-static/niro-logo-symbol.svg'} alt="Niro Logo" />
				<div></div>
				<div className="header-profile">
					<span className="header-profile-name">{profile.displayName}</span>
					{profile.displayName && <a href="#" onClick={() => this.props.logout()} className="header-profile-logout">Logout</a>}

					{/* <div className="header-profile-dropdown">
						<a href="#">Logout</a>
					</div> */}
				</div>
			</div>
		</header>
	}
}

export default Header;
